@import 'figma-desktop';
@import 'mixins';

.menu {
    z-index: 1000;
    position: fixed;
    width: 100%;
    height: 0px;

    .btn-menu-container {
        height: 0px;
        position: fixed;
        max-width: 1450px; // help restrict where the btn-menu sits - just to the right of the main website content

        .btn-menu {
            position: absolute;
            right: 0px;
            top: 31px;
            cursor: pointer;
        }
    }

    .btn-x {
        cursor: pointer;
        margin-top: 0px;
        margin-left: 0px;
        display: flex;
        justify-items: center;
    }

    button.parent {
        .arrow {
            display: inline-block;
        }
    }

    .button-container.expanded {
        .arrow {
            transform: rotate(-180deg);
        }
    }

    .dropdown {
        height: 100vh; //1000px;
        background-color: $color-jp-blue;
        transform: translateY(-100%);
        overflow-y: scroll;

        .container-wrapper {
            .container {
                padding-top: 110px;
                padding-bottom: 120px;

                .btn-x-container {
                    margin-top: 0px;
                    flex: 1;

                    .btn-x {
                        padding-right: 20px;

                        img {
                            width: 22px;
                            height: 22px;
                        }
                    }
                }
            }
        }
    }

    ul {
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        max-width: 90%;

        li {
            display: inline;
            list-style-type: none;
            margin: 0;
            flex: 0;

            .button-container {
                button {
                    padding-left: 30px;
                    padding-bottom: 15px;
                    font-size: 54px;
                    line-height: 48px;
                }

                .btn-arrow.parent {
                    display: block;
                }
            }

            // child-nav
            ul {
                max-height: 0;
                overflow: hidden;
                transition: max-height 0.5s ease-out;

                display: flex;
                flex-direction: column;
                padding-top: 0px;
                padding-left: 15px;
                margin-right: -50px; // allows this to extend into the next container to the right

                li {
                    .button-container {
                        button {
                            padding-top: 10px;
                            font-size: 24px;
                            line-height: 30px;
                            color: $color-white;
                            padding-left: 15px;
                        }
                    }
                }
            }
        }
    }

    .active {
        text-decoration: underline;
    }

    .social {
        padding-left: 20px;
    }

    .slide-enter {
        transform: translateY(-100%);
    }
    .slide-enter-active {
        transform: translateY(0%);
        transition: transform 300ms;
    }
    .slide-enter-done {
        transform: translateY(0%);
    }
    .slide-exit {
        transform: translateY(0%);
    }
    .slide-exit-active {
        transform: translateY(-100%);
        transition: transform 300ms;
    }
    .slide-exit-done {
        transform: translateY(-100%);
    }
}

.footer {
    height: 158px;
    background: #111111;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    color: $color-white;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    padding-left: 30px;
    padding-right: 30px;

    a {
        text-decoration: none;
        color: $color-white;
    }

    a.active {
        background-color: transparent;
    }

    .social {
        position: relative;
        left: auto;

        .inner-container {
            left: auto;
        }
        
        .linkedin img,
        .instagram img,
        .facebook img {
            height: 30px;
            padding: 8px;
            object-fit: contain;
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }
}

.background {
    height: 90vh;
}

.page-header {
    height: 90vh;
    color: $color-white;

    video {
        //width: 100%;
        height: 100%;
        width: auto;
        left: -150%;
    }

    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .title {
            font-family: $font-family-sofia;
            font-style: normal;
            font-weight: 500;
            font-size: 55px;
            line-height: 48px;
            display: block;
        }


        @media only screen and (max-width: 500px) {
            .title {
                font-size: 35px;
            }
        }

        .subtitle {
            @include font-desktop-halyard-h-5;
            display: block;
        }

        .arrow-down {
            margin-top: 50px;
        }
    }
}

.hover-card {
    height: auto;
    display: flex;
    flex-direction: column;
    position: static;
    border: none;
    width: 100%;
    margin-bottom: 60px;

    .overlay {
        position: relative;

        .title-img {
            position: static;
        }

        .title {
            position: absolute;
            bottom: 0px;
        }
    }

    .underlay {
        margin-left: 30px;
        margin-right: 30px;
        background-color: transparent;

        .underlay-img {
            display: none;
        }

        .title {
            display: none;
        }

        .description {
            margin-top: 30px;
            margin-bottom: 20px;
        }

        button {
            margin-bottom: 0px;
        }
    }

    .fade-enter {
        opacity: 1;
    }
    .fade-enter-active {
        opacity: 1;
        transition: opacity 300ms;
    }
    .fade-enter-done {
        opacity: 1;
        display: flex !important;
    }
    .fade-exit {
        opacity: 1;
    }
    .fade-exit-active {
        opacity: 1;
        transition: opacity 300ms;
    }
    .fade-exit-done {
        opacity: 1;
    }
}

.card-grid {
    width: 100%;
}

.next-story {
    .before-image-overlay {
        background-color: $color-black;
        opacity: 0.4;
    }

    .container {
        margin-bottom: 44px;
        align-items: flex-start;
        justify-content: flex-end;
        height: 375px;

        .subtitle {
            display: flex;
            flex-direction: row;
            width: 100%;

            span {
                flex: 1;
            }

            img {
                flex: 0;
                margin-right: 15px;
            }
        }

        button {
            display: none;
        }
    }
}

.what-we-do {
    .container {
        margin-top: 89px;
        margin-bottom: 35px;

        .card-grid {
            .card {
                margin-right: 0px;
                max-width: 100%;
            }
        }
    }
}

.what-we-do.full {
    .container {
        .card-grid {
            .card {
                margin-bottom: 76px;
            }
        }
    }
}

.what-we-do.compact {
    .container {
        .card-grid {
            .card {
                .btn {
                    display: none;
                }
            }
        }
    }
}

.team-hover-card {
    margin-bottom: 51px;

    .underlay {
        button {
            display: none;
        }
        .title {
            display: block;
            margin-top: 26px;
        }
        .role {
            margin-bottom: 18px;
        }
        .quote {
            display: block;
            margin-bottom: 51px;
            font-style: italic;
            font-weight: bold;
        }
        .bio {
            display: none;
        }
    }

    .fade-enter {
        opacity: 1;
    }
    .fade-enter-active {
        opacity: 1;
        transition: opacity 300ms;
    }
    .fade-enter-done {
        opacity: 1;
        display: flex !important;
    }
    .fade-exit {
        opacity: 1;
    }
    .fade-exit-active {
        opacity: 1;
        transition: opacity 300ms;
    }
    .fade-exit-done {
        opacity: 1;
    }
}

//
// Page Builder Modules
//
.two_column_module {
    .container {
        flex-direction: column;

        div {
            margin-right: 0px;
        }
    }
}


.form {
    flex-direction: column;

    .col-1 {
        margin-right: 0px;
    }
}

//
// Pages
//

.contact-page {
    .form {
        .row-1 {
            flex-direction: column;

            .col-1 {
                margin-right: 0px;

                input {
                    width: 100%;
                }
            }

            .col-2 {
                textarea {
                    height: 333px;
                }
            }
        }

        .row-2 {
            button {
                width: 100%;
                height: 50px;
            }
        }
    }

    .map {
        height: 532px;
    }
}

.news-feed-page {
    .page-header {
        height: 266px;

        .container {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .title {
                margin-top: 85px;
                margin-bottom: 44px;
            }
        }
    }

    .feed-container-wrapper {
        .container {
            width: 100%;

            .news-card {
                margin-bottom: 60px;

                .image {
                    width: 100%;
                    height: 322px;
                }

                .title {
                    margin-left: 36px;
                    margin-right: 36px;
                }

                .tags {
                    margin-left: 36px;
                    margin-right: 36px;
                }

                .excerpt {
                    margin-left: 36px;
                    margin-right: 36px;
                }

                button {
                    margin-left: 36px;
                    margin-right: 36px;
                }
            }
        }
    }
}

.landing-page {
    .logo {
        width: 300px;
        height: 300px;

        img {
            width: 290px;
            height: 290px;
        }
    }
}